export const destructionTableData = [
  {
    row: "1.",
    category: "Constitutional / corporate records",
    period: "",
    requirements: " ",
  },
  {
    row: "2",
    category: "Statutory registers",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "3",
    category:
      "Constitutional documents including:- certificates of incorporation/name change;",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "4",
    category: "memorandum   and   articles of association; resolutions",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "5",
    category: "- shareholder agreements",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "6",
    category: "- directors’ service contracts",
    period: "6 years after expiry",
    requirements: " ",
  },
  {
    row: "7",
    category: "- contract for purchase of own shares",
    period: "10 years from purchase",
    requirements: " ",
  },
  {
    row: "8",
    category: "Share issuance documents",
    period: "Later of 6 years and date on which shares are fully paid",
    requirements: " ",
  },
  {
    row: "9",
    category: "Share transfers and related documents, including:",
    period:
      "10 years if on or after 6 April 2008; 20 years if before that date",
    requirements: " ",
  },
  {
    row: "10",
    category: " share and stock transfer forms",
    period:
      "10 years if on or after 6 April 2008; 20 years if before that date",
    requirements: " ",
  },
  {
    row: "11",
    category: "-letters of indemnity for lost certificates",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "12",
    category: "cancelled share/stock certificates",
    period: "1 year after registration or transfer",
    requirements: " ",
  },
  {
    row: "13",
    category: "Other share registration documents including:",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "14",
    category: "notifications of member changes of address",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "15",
    category: "- evidence submitted in support of changes to register",
    period: "10 years if after 6 April 2008; 20 years if before that date",
    requirements: " ",
  },
  {
    row: "16",
    category: "- powers of attorney",
    period: "12 years after expiry",
    requirements: " ",
  },
  {
    row: "17",
    category: "- court notices",
    period:
      "Until expiry / 20 years if evidence of reason for action taken before 6 April 2008 / 10 years if evidence of action taken on or after that date",
    requirements: " ",
  },
  {
    row: "18",
    category: "Dividend/interest",
    period: "6 years after payment / expiry, as applicable",
    requirements: " ",
  },
  {
    row: "19",
    category: "Documents relating to director meetings including",
    period: "Life of company",
    requirements: " ",
  },
  {
    row: "20",
    category: "Documents for shareholder meetings",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "21",
    category: "Tax and accounting records",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "22",
    category:
      "Any documents which help to calculate tax liability for assessment of corporation tax, PAYE, VAT, national insurance, insurance premium tax, stamp duty land tax, stamp duty reserve tax, transfer pricing, aggregates levy, climate change levy, landfill tax, excise duty, bank payroll tax and direct taxes claims not included in a return",
    period: "20 years after financial year end",
    requirements: " ",
  },
  {
    row: "23",
    category: "Accounting records including:",
    period: "20 years after financial year end",
    requirements: " ",
  },
  {
    row: "24",
    category:
      "- report and accounts; half yearly report and accounts; interim management statements",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "25",
    category: "- budgets and periodic internal financial reports",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "26",
    category: "Compliance records",
    period: "",
    requirements: " ",
  },
  {
    row: "27",
    category:
      "Documents relating to money laundering regulations including evidence of clients’ identity and supporting records",
    period: "5 years after business relationship ends / transaction completes",
    requirements: " ",
  },
  {
    row: "28",
    category:
      "Anti-bribery and corruption policy, registers of gifts, political donations and entertainment, internal risk assessment materials, external due diligence materials, training materials and monitoring/audit materials",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "29",
    category: "HR and employment records",
    period: "",
    requirements: " ",
  },
  {
    row: "30",
    category:
      "Recruitment records for unsuccessful candidates including job applications, references and interview records",
    period:
      "Shore employees and contractors: no longer than 12 months after notifying unsuccessful candidate (unless candidate is notified and gives consent to further period/records are required to defend an action). Seafarers: no longer than 24 months after notifying unsuccessful candidate (unless candidate is notified and gives consent to further period/records are required to defend an action).",
    requirements: " ",
  },
  {
    row: "31",
    category: "Onboarding:",
    period: "",
    requirements: " ",
  },
  {
    row: "32",
    category: "Right to work and visa records",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "33",
    category: "References and qualifications, induction and training records",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "34",
    category:
      "Personnel and training records including recruitment records, qualifications and references, assessment reports, job histories, resignation / termination / retirement letters, disciplinary matters, employment contracts Senior executive records",
    period:
      "Employees: 6 years after employment ceases Seafarers: 12 years after engagement/employ ment ceases",
    requirements: " ",
  },
  {
    row: "35",
    category: "Senior executive records",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "36",
    category: "Working Time Regulations 1998 related documents including:",
    period: "",
    requirements: " ",
  },
  {
    row: "37",
    category: "- opt-out forms",
    period:
      "Longer of 2 years from date entered into and period for which opt-out is in force",
    requirements: " ",
  },
  {
    row: "38",
    category:
      "- other documents showing compliance including time sheets for opted out workers and health assessments for night workers",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "39",
    category: "Annual leave records",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "40",
    category: "Travel and subsistence claims and authorisations",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "41",
    category: "Wage records",
    period: "6 years after financial year end",
    requirements: " ",
  },
  {
    row: "42",
    category: "National minimum wage records",
    period:
      "3 years after the end of the pay reference period following the one that the records cover",
    requirements: " ",
  },
  {
    row: "43.",
    category: "Statutory sick pay records including dates and payments",
    period: "6 years after financial year end",
    requirements: " ",
  },
  {
    row: "44.",
    category: "Absence records showing dates and causes of absence",
    period: "6 years after financial year end",
    requirements: " ",
  },
  {
    row: "45.",
    category: "Parental leave records",
    period:
      "5 years from birth/adoption of the child or 18 years if the child receives a disability allowance",
    requirements: " ",
  },
  {
    row: "46.",
    category: "Gender pay reports",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "47.",
    category: "Income tax and national insurance returns",
    period: "3 years",
    requirements: " ",
  },
  {
    row: "48.",
    category: "Pay records and pay slips",
    period: "3 years after pay reference period",
    requirements: " ",
  },
  {
    row: "49.",
    category: "Salary/wage records, including overtime, bonuses, expenses",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "50.",
    category: "Performance appraisal records",
    period: "6 years after employment endsmanently",
    requirements: " ",
  },
  {
    row: "51.",
    category: "Learning and development records",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "52.",
    category: "Employee engagement records",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "53.",
    category: "Employee bank details",
    period: "Duration of employment and no longer than necessary",
    requirements: " ",
  },
  {
    row: "54.",
    category: "Labour agreements",
    period: "10 years after expiry",
    requirements: " ",
  },
  {
    row: "55.",
    category:
      "Disclosure and Barring Service checks and disclosures of criminal records",
    period:
      "Until end of recruitment period, unless relevant to employment. Must be destroyed once conviction is spent (unless employment type is excluded)",
    requirements: " ",
  },
  {
    row: "56.",
    category: "Immigration checks",
    period: "2 years after employment ceases",
    requirements: "",
  },
  {
    row: "57.",
    category: "Redundancy records",
    period: "6 years",
    requirements: "",
  },
  {
    row: "58.",
    category: "Equal opportunities/diversity monitoring documents",
    period: "6 years",
    requirements: "",
  },
  {
    row: "59.",
    category: "Leavers – reason for termination / dism issal",
    period: "6 years",
    requirements: "",
  },
  {
    row: "60.",
    category:
      "3 years after end of tax year in which the maternity pay period ends",
    period: "",
    requirements: "",
  },
  {
    row: "61.",
    category: "Pensions records",
    period: "",
    requirements: "",
  },
  {
    row: "62.",
    category:
      "Pension scheme records for occupational pension schemes including:",
    period: "",
    requirements: "",
  },
  {
    row: "63.",
    category:
      "- all pension scheme trust deeds and rules; tax approvals - details of benefits paid to dependents on member death - details of benefits paid to dependents on member death",
    period: "12 years after scheme benefits no longer available ",
    requirements: "",
  },
  {
    row: "64",
    category: "All employer pension records for individual pension payments",
    period: "",
    requirements: "",
  },
  {
    row: "65.",
    category: "Property records",
    period: " ",
    requirements: " ",
  },
  {
    row: "66.",
    category: "Lease agreements; lease guarantees",
    period:
      "15 years after expiry of term or, if assigned, liability (originals)",
    requirements: " ",
  },
  {
    row: "67.",
    category: "Subletting agreements",
    period: "12 years after expiry or termination (originals)",
    requirements: " ",
  },
  {
    row: "68.",
    category: "Landlord's consents; licences",
    period:
      "15 years after expiry or termination of lease, or if assigned, liability (originals)",
    requirements: " ",
  },
  {
    row: "69.",
    category: "Planning consents",
    period: "Until sale or lease expiry (originals)",
    requirements: " ",
  },
  {
    row: "70.",
    category: "Surveys and inspections documents",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "71.",
    category: "Reports:",
    period: " ",
    requirements: " ",
  },
  {
    row: "72.",
    category:
      "- architectural reports; building condition surveys; conservation reports (if listed building); site surveys; maps; plans; drawings",
    period: "Later of date of sale and 25 years",
    requirements: " ",
  },
  {
    row: "73.",
    category:
      "- structural, mechanical,electrical and drainage engineering reports",
    period: "Later of date of sale and 15 years",
    requirements: " ",
  },
  {
    row: "74.",
    category: "-asbestos inspections",
    period: "Later of date of sale and 40 years",
    requirements: " ",
  },
  {
    row: "75.",
    category: "- project health and safety file",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "76.",
    category: "Maintenance contracts and related files",
    period: "6 years after expiry of contract (12 years if deeds)",
    requirements: " ",
  },
  {
    row: "77.",
    category: "Maintenance schedules,programmes and logs",
    period: "15 years",
    requirements: " ",
  },
  {
    row: "78.",
    category: "Asset registers",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "79.",
    category: "Intellectual property records",
    period: "",
    requirements: " ",
  },
  {
    row: "80.",
    category:
      "Trade mark files including evidence of earliest use of trade mark and trade mark applications",
    period: "15 years after expiry or non-use of trade mark",
    requirements: " ",
  },
  {
    row: "81.",
    category:
      "Certificates of registration and documents evidencing assignment of trade/service marks and designs",
    period: "15 years after expiry of intellectual property (originals)",
    requirements: " ",
  },
  {
    row: "82.",
    category: "Intellectual property agreements / licenses",
    period: "15 years after expiry",
    requirements: " ",
  },
  {
    row: "83.",
    category: "Deal-related information",
    period: "",
    requirements: " ",
  },
  {
    row: "84.",
    category: "Legal, financial and commercial due diligence reports",
    period: "6 years from completion of transactions",
    requirements: "",
  },
  {
    row: "85.",
    category: "Sale and purchase agreements and related documents",
    period:
      "6 years after transaction completes where executed as contracts; 12 years where executed as deeds",
    requirements: " ",
  },
  {
    row: "86.",
    category: "Financing agreements and related documents",
    period:
      "6 years after transaction completes where executed as contracts; 12 years where executed as deeds",
    requirements: " ",
  },
  {
    row: "87.",
    category:
      "Authorisations and approvals e.g. board minutes, shareholder resolutions",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "88.",
    category: "Health and safety records",
    period: "",
    requirements: " ",
  },
  {
    row: "89.",
    category: "Health and safety policy",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "90.",
    category: "Record of employee health and safety training",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "91.",
    category: "Risk assessments and reviews",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "92.",
    category: "Fire risk assessments",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "93.",
    category:
      "Records of any reportable injury, disease or dangerous occurrence",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "94.",
    category: "Accident book (Form BI 510)",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "95.",
    category: "Records of personal information, policies and procedures",
    period: "",
    requirements: " ",
  },
  {
    row: "96.",
    category: "Data protection policies",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "97.",
    category:
      "Records relating to development, implementation and review of information management policy, information surveys and record audits",
    period: "5 years",
    requirements: " ",
  },
  {
    row: "98.",
    category: "Information systems records including quality control log,",
    period: "As long as information",
    requirements: " ",
  },
  {
    row: "99.",
    category: "Records/information management records including:",
    period: "",
    requirements: " ",
  },
  {
    row: "100.",
    category: "- review lists",
    period: "5 years",
    requirements: " ",
  },
  {
    row: "101.",
    category:
      "- lists, certificates, docket books or databases of records destroyed ",
    period: "Life of company",
    requirements: " ",
  },
  {
    row: "102.",
    category: "- register of records held belonging to other organisations",
    period: "As long as records held",
    requirements: " ",
  },
  {
    row: "103.",
    category: "Storage records including:",
    period: "",
    requirements: " ",
  },
  {
    row: "104.",
    category:
      "- lists of records transferred to storage; details of security of records",
    period: "5 years",
    requirements: " ",
  },
  {
    row: "105.",
    category: "- records of retrieval of records from storage",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "106.",
    category: "- records of tracking and location systems",
    period: "Until systems superseded",
    requirements: " ",
  },
  {
    row: "107.",
    category: "- records relating to contracts with storage providers",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "108.",
    category: "General administrative records and correspondence",
    period: "2 years",
    requirements: " ",
  },
  {
    row: "109.",
    category: "Training records including audio- visual materials",
    period: "5 years / until superseded",
    requirements: " ",
  },
  {
    row: "110.",
    category: "Contracts, sales, marketing and insurance records",
    period: "",
    requirements: " ",
  },
  {
    row: "111.",
    category: "Contracts executed as deeds",
    period: "12 years after performance",
    requirements: " ",
  },
  {
    row: "112.",
    category: "Other contracts",
    period: "6 years after performance",
    requirements: " ",
  },
  {
    row: "113.",
    category: "Contracts relating to building, maintenance and repairs",
    period: "15 years after performance",
    requirements: " ",
  },
  {
    row: "114.",
    category: "Sales and marketing records:",
    period: "",
    requirements: " ",
  },
  {
    row: "115.",
    category: "- standard conditions terms and",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "116.",
    category: "Product liability and safety records",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "117.",
    category: "Insurance records including:",
    period: "",
    requirements: " ",
  },
  {
    row: "118.",
    category: "- policies, proposal forms, renewal notices and certificates",
    period: "Later of 10 years and until all claims are barred / settled",
    requirements: " ",
  },
  {
    row: "119.",
    category: "- claims correspondence",
    period: "3 years after settlement",
    requirements: " ",
  },
  {
    row: "120.",
    category: "Emails",
    period: "",
    requirements: " ",
  },
  {
    row: "121.",
    category: "Email correspondence relating to:",
    period: "",
    requirements: " ",
  },
  {
    row: "122.",
    category:
      "- entry into contracts and / or providing evidence of negotiations",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "123.",
    category: "- entry into deeds and / or providing evidence of negotiations",
    period: "12 years",
    requirements: " ",
  },
  {
    row: "124.",
    category: "- advice given to clients",
    period: "Permanently",
    requirements: " ",
  },
  {
    row: "125.",
    category: "Purchasing Records",
    period: "",
    requirements: " ",
  },
  {
    row: "126.",
    category:
      "General ledger, accounts receivable, accounts payable, sales admin, inventory",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "127.",
    category: "Procurement records",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "128.",
    category: "Debtors and creditors",
    period: "6 years",
    requirements: " ",
  },
  {
    row: "129.",
    category:
      "Customers and suppliers records including advice given to clients",
    period: "6 years",
    requirements: " ",
  },
];
