import React from "react";
import Layout from "components/layout";
import DataDestructionPolicy from "components/DataDestructionPolicy";
import SEO from "components/seo";

function Index() {
  return (
    <Layout>
      <SEO />
      <DataDestructionPolicy />
    </Layout>
  );
}

export default Index;
