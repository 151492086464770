import * as React from "react";

const Text = ({
  children,
  className = "",
  as: Tag = "span",
  highlightedText = [],
  highlightedColor = "lightBlue",
  onClick = undefined,
  ...rest
}) => {
  const TextDefaultClass = {
    h1: "text-4xl2 font-secondary font-semibold my-5",
    h2: "text-3xl2 font-secondary font-semibold my-5",
    h3: "text-2xl2 font-secondary font-semibold",
    h4: "text-3xl2 font-secondary font-semibold",
    h5: "text-lg font-secondary font-semibold",
    p: "text-sm2 font-opensans font-normal",
    span: "text-sm2 font-opensans font-normal",
    div: "text-sm2 font-opensans font-normal",
    li: "text-sm2 font-opensans font-normal",
    ul: "text-sm2 font-opensans font-normal",
    ol: "text-sm2 font-opensans font-normal",
    a: "text-sm2 font-opensans font-normal cursor-pointer",
  };

  // function transformContent(content, keywords) {
  //   let temp = content;
  //   //for loop to replace keyword
  //   keywords.forEach((keyword) => {
  //     temp = temp.replace(
  //       new RegExp(keyword, "ig"),
  //       wrapKeywordWithHTML(keyword)
  //     );
  //   });

  //   return temp;
  // }
  function transformContent(content, keywords) {
    let temp = content;

    // Ensure keywords is an array
    if (!Array.isArray(keywords)) {
      keywords = [keywords];
    }

    // Loop through keywords and replace them in the content
    keywords.forEach((keyword) => {
      const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      temp = temp.replace(
        new RegExp(escapedKeyword, "ig"),
        wrapKeywordWithHTML(keyword)
      );
    });

    return temp;
  }


  function wrapKeywordWithHTML(keyword) {
    return `<span class="text-${highlightedColor}">  ${keyword} </span>`;
  }

  if (typeof children !== "string") {
    return (
      <Tag
        onClick={onClick}
        {...rest}
        className={`${className ? className : TextDefaultClass[Tag]}`}
      >
        {children}
      </Tag>
    );
  } else {
    return (
      <Tag
        {...rest}
        onClick={onClick}
        className={`${className ? className : TextDefaultClass[Tag]}`}
        dangerouslySetInnerHTML={{
          __html: highlightedText.length
            ? transformContent(children, highlightedText)
            : children,
        }}
      ></Tag>
    );
  }
};

export default Text;
