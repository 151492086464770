import React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import { Link } from "gatsby";
import { destructionTableData } from "./smaple-data";

function DataDestructionPolicy({ background = "bg-darkBlue" }) {
  return (
    <BoxContainer background={background} className="md:px-5 2xl:px-0 py-16">
      <div className="max-w-6xl mx-auto">
        <Text
          as="h2"
          className="text-45 text-white text-center font-secondary font-semibold pb-12"
        >
          Data Retention & Destruction Policy
        </Text>
        <div className="flex flex-col gap-8">
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              1<span>INTRODUCTION</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                1.1
                <span>
                  This document retention and destruction policy (the "Policy")
                  sets out how Tomedes retains Records necessary for ongoing
                  business operations, legal, audit and regulatory compliance,
                  and destroys such records, whilst promoting orderly and
                  efficient records management.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                1.2
                <span>
                  The law requires Tomedes to maintain certain records for
                  certain periods of time. Failure to retain those records for
                  those periods could subject Tomedes to adverse consequences.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                1.3
                <div>
                  <span>This Policy:</span>
                  <br />
                  <p>
                    (a) &nbsp;&nbsp;&nbsp;forms part of Tomedes’ Information
                    Security Policy;
                  </p>
                  <p>
                    (b) &nbsp;&nbsp;&nbsp;may be amended by Toemdes at any time,
                    consistent with the requirements of applicable laws and
                    regulations. Any revisions will take effect from the date on
                    which the amended Policy is published, as indicated by the
                    version number; and
                  </p>
                  (c) &nbsp;&nbsp;&nbsp;must be complied with unless you have
                  reason to believe that:
                  <p className="pl-[40px]">
                    (i) a Proceeding is pending or reasonably anticipated, in
                    which case you must immediately contact Group Legal for
                    further guidance before destroying, altering, moving or
                    rearranging any Records; or
                    <br />
                    (ii) Group Legal has ordered a Hold Instruction in which
                    case Records should be maintained for review by Group Legal.
                    <br />
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              2<span>DEFINITIONS</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                2.1
                <div className="flex flex-col gap-2">
                  <span>"Colleague" means any member of Tomedes;</span>
                  <span>
                    "Group Legal" means the legal function of Tomedes;
                  </span>
                  <span>
                    "Hold Instruction" means an instruction from Group Legal to
                    preserve documents in relation to a Proceeding;
                  </span>
                  <span>
                    "Proceeding" means any legal, official, governmental or
                    other proceeding or investigation;
                  </span>
                  <span>
                    "Records" means all documents and other data (including
                    Personal Data) that are created, received, used or
                    distributed by Tomedes in the course of its business,
                    regardless of medium and includes without limitation hard
                    copy, soft copy, Word, Excel or PowerPoint files, emails
                    (inbox, outbox, drafts, folders and trash files), cloud
                    storage, instant messages, intranet and other media; and
                  </span>
                  <span>
                    "Tomedes" means Tomedes Ltd and its subsidiaries and/or
                    affiliates.
                  </span>
                </div>
              </li>
              <li className="flex gap-3 items-start">
                2.2
                <span>
                  Words denoting the singular shall include the plural and vice
                  versa.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                2.3
                <span>
                  Unless otherwise stated, all defined terms have the same
                  meaning as defined in the Data Protection Policy.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              3<span>GENERAL PRINCIPLES</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                3.1
                <span>
                  You must consistently follow this Policy to ensure Tomedes’
                  compliance with all applicable recordkeeping requirements in
                  the relevant territory. Tomedes’ are required to ensure that
                  complete and accurate records are identified, retained,
                  stored, protected and subsequently destroyed or archived
                  within their area of assigned responsibility and in accordance
                  with this Policy. All levels of management are responsible for
                  ensuring that all staff under their supervision comply with
                  this Policy.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                3.2
                <div>
                  <span>The principal objectives of this Policy are:</span>
                  <br />
                  (a) &nbsp;&nbsp;&nbsp;to ensure that Records:
                  <p className="pl-[40px]">
                    (i) are retained appropriately including for the appropriate
                    period;
                    <br />
                    (ii) which may need to be tendered as evidence in
                    Proceedings are kept in a manner that ensures they will be
                    admissible in those Proceedings;
                    <br />
                    (iii) are retained only as long as those Records serve the
                    immediate purposes for which they were created; and <br />
                    (iv) are permanently archived in accordance with law after
                    their retention period has lapsed and they are no longer
                    needed;
                  </p>
                  <p>
                    (b) &nbsp;&nbsp;&nbsp;to ensure that Tomedes’ information
                    needs are met;
                  </p>
                  <p>
                    (c) &nbsp;&nbsp;&nbsp;to minimise the retention of Records
                    after carefully assessing risks;
                  </p>
                  <p>
                    (d) &nbsp;&nbsp;&nbsp;to ensure that all disposal of Records
                    is efficient, orderly, appropriate and properly recorded.
                  </p>
                </div>
              </li>
              <li className="flex gap-3 items-start">
                3.3
                <span>
                  This Policy applies to Records located anywhere including
                  without limitation in Tomedes’ premises, personal homes of
                  Colleagues and contractors, and storage facilities.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              4<span>RETENTION PROCEDURES</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                4.1
                <span>
                  Records must be categorized by purpose and retained for
                  specific periods in accordance with Schedule 1
                </span>
              </li>
              <li className="flex gap-3 items-start">
                4.2
                <span>
                  Tomedes must comply with General Data Protection Regulation
                  (GDPR) which requires that Personal Data must not be kept
                  longer than is necessary for the purpose for which it was
                  collected. This requires that Personal Data must be physically
                  destroyed and/or erased and purged from Tomedes’ systems when
                  it is no longer required, unless a minimum retention period is
                  specified or a Hold Instruction has been issued in relation to
                  the relevant Record
                </span>
              </li>
              <li className="flex gap-3 items-start">
                4.3
                <span>
                  Personal Data must be accurate and kept up to date. Inaccurate
                  or out of date Personal Data must be rectified or
                  destroyed/erased.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                4.4
                <span>
                  Retention periods vary depending on the country in which the
                  Records are kept. For further information about the retention
                  period for each category of Record, refer to Schedule 1.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              5<span>HOLD INSTRUCTION</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                5.1
                <span>
                  In the case of an actual, pending or reasonably anticipated
                  Proceeding, Group Legal may issue a Hold Instruction.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                5.2
                <span>
                  If a Hold Instruction is ordered or you otherwise believe a
                  Proceeding is pending or reasonably anticipated, Records must
                  be maintained for review by Group Legal. If you believe you
                  may have material related to a Proceeding but require
                  assistance, promptly contact Group Legal. You must not delete,
                  discard or destroy any Records you may have that may be
                  subject to a Hold Instruction. If you have any doubt whether a
                  Record is relevant to a Hold Instruction, save it and provide
                  it to Group Legal.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                5.3
                <span>
                  If a Record is subject to a Hold Instruction, that Record must
                  be retained until the Hold Instruction is removed by Group
                  Legal.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                5.4
                <span>
                  Failure to comply with this Paragraph, to seek guidance and
                  preserve Records can result in serious adverse consequences to
                  Tomedes and any person involved.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              6<span>DESTRUCTION OF DOCUMENTS</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                6.1
                <span>
                  It is unlawful to destroy, conceal or falsify any Record for
                  the purpose of obstructing or influencing any Proceeding.
                  Doing so may subject Tomedes and any offending individuals to
                  civil and criminal penalties including fines and imprisonment.
                </span>
              </li>
              <li className="flex gap-3 items-start">
                6.2
                <div>
                  <span>
                    You are only permitted to destroy Records when all the
                    following conditions are met:
                  </span>
                  <br />
                  <p>
                    (a) &nbsp;&nbsp;&nbsp;no improper motive for the destruction
                    of the Records;
                  </p>
                  <p>
                    (b) &nbsp;&nbsp;&nbsp;no legal or regulatory reason to
                    maintain the Records;
                  </p>
                  <p>
                    (c) &nbsp;&nbsp;&nbsp;the destruction is in accordance with
                    mandated retention periods as set out in Schedule 1;1 and 1
                    General Data Protection Regulation (GDPR), there is no
                    mandatory retention periods for personal data. However, in
                    relation to the following records, they should be kept for
                    the following periods:
                    <br />
                    (i) employment records – 4 years; and
                    <br />
                    (ii) contracts – 10 years.
                  </p>
                  <p>(d) &nbsp;&nbsp;&nbsp;no Hold Instruction is in place.</p>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              7<span>END OF SERVICE</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                7.1
                <span>
                  Each Colleague must return to Tomedes or destroy, at Tomedes’
                  option, all Records in their possession upon the end of their
                  service with Tomedes. This includes, without limitation,
                  Records located anywhere including without limitation in
                  Tomedes’ premises, personal homes of Colleagues and storage
                  facilities.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-24 text-white font-secondary font-medium pb-4 flex gap-5 items-start">
              8<span>DOCUMENT CONTROL</span>
            </h3>
            <ul className="text-lg text-white leading-8 font-opensans flex flex-col gap-3">
              <li className="flex gap-3 items-start">
                8.1
                <span>
                  The DPO is the owner of this Policy and is responsible for
                  ensuring that it is reviewed in line with the relevant review
                  requirements.
                </span>
              </li>
              <li className="w-full flex gap-3 items-start max-w-full overflow-x-hidden">
                8.2
                <p>
                  A current version of this Policy is available at&nbsp;
                  <a
                    href="https://learn.zoho.com/portal/tomedes/team/tomedes-humanresource-department"
                    target="_blank"
                    className="underline text-orange hover:text-newOrange-100"
                  >
                    https://learn.zoho.com/portal/tomedes/team/tomedes-humanresource-department
                  </a>
                </p>
              </li>
              <li className="flex gap-3 items-start">
                8.3
                <span>
                  This Policy was approved as stated in this Paragraph and is
                  issued on a version- controlled basis.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <h3 className="text-24 text-white font-secondary font-medium pt-16 pb-7">
          Schedule 1 Record retention periods
        </h3>
        <div className="overflow-x-scroll sm:overflow-auto">
          <table class="table-auto text-lg leading-7 text-white font-opensans overflow-x-scroll sm:overflow-auto">
            <thead>
              <tr>
                <th className="py-2 px-4 text-center">Row</th>
                <th className="py-2 px-4 text-center">Records category</th>
                <th className="py-2 px-4 text-center">Retention period</th>
                <th className="py-2 px-4 text-center">
                  Specific local retention requirements
                </th>
              </tr>
            </thead>
            <tbody className="data-destruction-table">
              {destructionTableData.map((item, i) => {
                return (
                  <tr>
                    <td>{item.row}</td>
                    <td>{item.category}</td>
                    <td>{item.period}</td>
                    <td>{item.requirements}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </BoxContainer>
  );
}
export default DataDestructionPolicy;
